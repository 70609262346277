var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',[(_vm.isIntentoUser)?_c('b-breadcrumb-item',{attrs:{"to":{ name: 'intentoDashboard' }}},[_c('b-icon',{attrs:{"icon":"house-fill","scale":"1.25","shift-v":"1.25","aria-hidden":"true"}}),_vm._v(" Dashboard ")],1):_vm._e(),(_vm.isIntentoSales)?_c('b-breadcrumb-item',{attrs:{"to":{ name: 'inventory' }}},[_vm._v(" Inventory Management ")]):_vm._e()],1),_c('b-container',{attrs:{"fluid":""}},[_c('b-card',[_c('b-card-title',[_vm._v(" Inventory Management ")]),_c('b-card-body',[_c('b-row',[_c('b-col',[_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Serial Number")]),_c('th',[_vm._v("BLE Mac Address")]),_c('th',[_vm._v("Firmware Version")]),_c('th',[_vm._v("Customer")]),_c('th',[_vm._v("Site")]),_c('th',[_vm._v("Last seen")]),_c('th',[_vm._v("Action")])])]),_c('tbody',[(_vm.loading)?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('b-alert',{attrs:{"show":_vm.loading,"variant":"info"}},[_vm._v("Loading...")])],1)]):_vm._e(),_vm._l((_vm.devices),function(device){return _c('tr',{key:device.id},[_c('td',[(_vm.isUserAuthorized)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-edit-site",modifiers:{"modal-edit-site":true}}],staticClass:"mr-3",attrs:{"variant":"outline-primary"},on:{"click":function($event){$event.preventDefault();return _vm.populateEditModel(device)}}},[_c('b-icon-pencil')],1):_vm._e(),_vm._v(" "+_vm._s(device.intentoSerialNumber)+" ")],1),_c('td',[_vm._v(_vm._s(device.bleMacAddress))]),_c('td',[_vm._v(_vm._s(device.firmwareVersion))]),_c('td',[_vm._v(" "+_vm._s(device.customerAccountName)+" ")]),_c('td',[_vm._v(_vm._s(device.siteName))]),_c('td',[_vm._v(" "+_vm._s(_vm.toLocaleDateTime( device.lastSeen ))+" ")]),_c('td',[_c('b-button',{staticClass:"m-1",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                                                    name: 'siteDevices',
                                                    params: {
                                                        customerId:
                                                            device.customerAccountId,
                                                        siteId:
                                                            device.siteId
                                                    },
                                                })}}},[_c('b-icon-box-arrow-up-right')],1)],1)])})],2)])]),_c('b-modal',{attrs:{"id":"modal-edit-device","ok-title":"Save Device Information","title":'Edit Device ' + _vm.model.bleMacAddress},on:{"ok":_vm.saveDeviceInformation}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveDeviceInformation.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Intento Serial Number"}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.model.intentoSerialNumber),callback:function ($$v) {_vm.$set(_vm.model, "intentoSerialNumber", $$v)},expression:"model.intentoSerialNumber"}})],1)],1),_c('div',{staticClass:"form-group"},[(_vm.errorMessage)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }