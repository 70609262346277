/*
 * Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
 * and/or distributed without the express prior written permission of MindMaze Holding SA.
 */

export function formatDateTimeToLocaleString(d) {
    var date = new Date(d);
    return date.toLocaleString("en-GB");
}

export function formatDateToLocaleString(d) {
    var date = new Date(d);
    return date.toLocaleDateString("en-GB");
}

export function formatDateTimeToISOString(d) {
    var date = new Date(d);
    return date.toISOString();
}

export function formatDateToISOString(d) {
        var date = new Date(d);
        return date.toISOString().substring(0, 10)
}

export function formatSeconds(seconds) {
    function numberEnding (number) {
        return (number > 1) ? 's ' : '';
    }

    var txt = '';
    seconds %= 31536000
    var days = Math.floor(seconds / 86400);
    if (days) {
        txt += days + ' day' + numberEnding(days);
    }
    seconds %= 86400
    var hours = Math.floor(seconds / 3600);
    if (hours) {
        txt += ' ' + hours + ' hour' + numberEnding(hours);
    }
    seconds %= 3600
    var minutes = Math.floor(seconds / 60);
    if (minutes) {
        txt += ' ' + minutes + ' minute' + numberEnding(minutes);
    }
    var remainingSeconds = Math.floor(seconds % 60);
    if (remainingSeconds) {
        txt += ' ' + remainingSeconds + ' second' + numberEnding(remainingSeconds);
    }
    return txt;
}

export function formatExerciseName(s) {

    switch (s) {
        case 'stim_cm01':
            return 'Shoulder Abuction with External Rotation'

        case 'stim_cm02':
            return 'Object to mouth'

        case 'stim_cm03':
            return 'Open and close hand'

        case 'stim_cm04':
            return 'Close and open hand'

        case 'stim_cm05':
            return 'Reach Controlateral Shoulder'

        case 'stim_cm06':
            return 'Reach Frontal'

        case 'stim_cm07':
            return 'Reach Lateral'

        case 'stim_cm08':
            return 'Reach to grasp frontal + Hand open'

        case 'stim_cm09':
            return 'Reach to grasp frontal + Hand close'

        case 'stim_cm10':
            return 'Reach to grasp lateral + Hand open'

        case 'stim_cm11':
            return 'Reach to grasp lateral + Hand close'

        case 'stim_cm12':
            return 'Wrist and elbow extension'

        case 'stim_jm01':
            return 'Shoulder Abduction'

        case 'stim_jm02':
            return 'Shoulder Flexion'

        case 'stim_jm03':
            return 'Scapular Retraction'

        case 'stim_jm04':
            return 'Shoulder External Rotation'

        case 'stim_jm05':
            return 'Elbow Extension'

        case 'stim_jm06':
            return 'Elbow Flexion'

        case 'stim_jm07':
            return 'Wrist and Fingers Extension'

        case 'stim_jm08':
            return 'Hand supination with hand open'

        case 'stim_jm09':
            return 'Finger Flexion'

        case 'stim_jm10':
            return 'Thumb extension'

        default:
            return s

    }
}