<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item
                :to="{ name: 'intentoDashboard' }"
                v-if="isIntentoUser"
            >
                <b-icon
                    icon="house-fill"
                    scale="1.25"
                    shift-v="1.25"
                    aria-hidden="true"
                ></b-icon>
                Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item
                :to="{ name: 'inventory' }"
                v-if="isIntentoSales"
            >
                Inventory Management
            </b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid>
            <b-card>
                <b-card-title> Inventory Management </b-card-title>

                <b-card-body>
                    <b-row>
                        <b-col>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Serial Number</th>
                                        <th>BLE Mac Address</th>
                                        <th>Firmware Version</th>
                                        <th>Customer</th>
                                        <th>Site</th>
                                        <th>Last seen</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="loading">
                                        <td colspan="7">
                                            <b-alert
                                                :show="loading"
                                                variant="info"
                                                >Loading...</b-alert
                                            >
                                        </td>
                                    </tr>
                                    <tr
                                        v-for="device in devices"
                                        :key="device.id"
                                    >
                                        <td>
                                            <b-button
                                                class="mr-3"
                                                v-if="isUserAuthorized"
                                                variant="outline-primary"
                                                v-b-modal.modal-edit-site
                                                @click.prevent="
                                                    populateEditModel(device)
                                                "
                                            >
                                                <b-icon-pencil />
                                            </b-button>
                                            {{ device.intentoSerialNumber }}
                                        </td>
                                        <td>{{ device.bleMacAddress }}</td>
                                        <td>{{ device.firmwareVersion }}</td>
                                        <td>
                                            {{ device.customerAccountName }}
                                        </td>
                                        <td>{{ device.siteName }}</td>
                                        <td>
                                            {{
                                                toLocaleDateTime(
                                                    device.lastSeen
                                                )
                                            }}
                                        </td>
                                        <td>
                                            <b-button
                                                variant="outline-primary"
                                                size="sm"
                                                class="m-1"
                                                @click.prevent="
                                                    $router.push({
                                                        name: 'siteDevices',
                                                        params: {
                                                            customerId:
                                                                device.customerAccountId,
                                                            siteId:
                                                                device.siteId
                                                        },
                                                    })
                                                "
                                            >
                                                <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                                            </b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>

                        <b-modal
                            id="modal-edit-device"
                            @ok="saveDeviceInformation"
                            ok-title="Save Device Information"
                            :title="'Edit Device ' + model.bleMacAddress"
                        >
                            <form @submit.prevent="saveDeviceInformation">
                                <b-form-group label="Intento Serial Number">
                                    <b-form-input
                                        type="text"
                                        v-model="model.intentoSerialNumber"
                                    ></b-form-input>
                                </b-form-group>
                            </form>
                            <div class="form-group">
                                <div v-if="errorMessage" class="alert alert-danger" role="alert">
                                    {{ errorMessage }}
                                </div>
                            </div>
                        </b-modal>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";
import { formatDateTimeToLocaleString } from "@/components/utils.js";

export default {
    name: "Inventory",
    data() {
        return {
            loading: false,
            devices: [],
            model: {},
            errorMessage: ''
        };
    },
    async created() {
        this.refreshDevices();
    },
    computed: {
        ...mapGetters("auth", ["isIntentoSales", "isIntentoUser"]),
    },
    methods: {
        isUserAuthorized() {
            return this.isIntentoSales
        },
        async refreshDevices() {
            this.loading = true;

            if (this.isUserAuthorized) {
                this.devices = await ApiService.getIntentoProDevices();
            }

            this.loading = false;
        },
        populateEditModel(device) {
            this.model = Object.assign({}, device);
            this.$bvModal.show("modal-edit-device");
            this.errorMessage = '';
        },
        async saveDeviceInformation(event) {
            event.preventDefault();
            await ApiService.saveDeviceInformation(this.model.deviceId, this.model).then(
                            () => { 
                                this.model = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-edit-device");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
            this.refreshDevices();
        },
        toLocaleDateTime(d) {
            return formatDateTimeToLocaleString(d);
        },
    },
};
</script>

<style></style>
